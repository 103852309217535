import { hhoRpc } from '@/services/rpc/rpcSender';

const customerApiPrefix: string = '';
const getFullUrl = (pathUrl: string) => [customerApiPrefix, pathUrl].join('');

export interface IAiDesignParams {
  password: string;
  title: string;
  uuid: string;
}

export interface IAiDesignResult {
  designImageList: string[];
  hasMore?: boolean;
}

export const getAIDesign = (params: IAiDesignParams): Promise<IAiDesignResult> => {
  return hhoRpc({
    method: 'get',
    headers: {},
    payload: params,
    url: getFullUrl('/order_reviews/design/ai_design')
  });
};

export const getDesignTemplate = (params: any): Promise<any> => {
  return hhoRpc({
    method: 'get',
    headers: {},
    payload: params,
    url: getFullUrl('order_reviews/design/ai_design_details')
  });
};

export const uploadCustomizeImage = (payload: any) => {
  return hhoRpc({
    method: 'post',
    headers: {},
    payload,
    url: getFullUrl('/order_reviews/customize/upload_picture')
  });
};

export const uploadCustomizeImageV2 = (payload: any) => {
  return hhoRpc({
    method: 'post',
    headers: {},
    payload,
    url: getFullUrl('/order_reviews/customize/upload_picture_info')
  });
};

export interface ICheckoutModel {
  lineItemList: any;
  source: 'detail' | 'cart' | 'customize';
  cartId: number | string;
}

export const createCheckout = (payload: ICheckoutModel): Promise<any> => {
  return hhoRpc({
    method: 'post',
    url: '/trade/checkouts',
    headers: {},
    payload
  });
};

// 添加购物车参数
export interface ICartInputItemModel {
  quantity: number; // 数量
  skuId: string | number; // sku id
  customizeImage?: string;
  templateId?: number;
}

// 添加购物车
export const addCart = (payload: ICartInputItemModel): Promise<any> => {
  return hhoRpc({
    method: 'post',
    url: '/cart/sku/add',
    headers: {},
    payload
  });
};

export interface ICustomizeItemParams {
  item_id: string;
  sku_id?: string;
}

export interface ICustomizableRegion {
  customHeight: number;
  customWidth: number;
  imageHeight?: number;
  imageWidth?: number;
  mainImage: string;
  cutImage?: string;
  region: string;
  templateId: number;
  xResolution: number;
  yResolution: number;
}

export interface ICustomizeItemResult {
  recommendImageList: string[];
  customizableRegionList: ICustomizableRegion[];
}

export const getCustomizeItem = (payload: ICustomizeItemParams): Promise<ICustomizeItemResult> => {
  return hhoRpc({
    method: 'get',
    headers: {},
    payload,
    url: 'order_reviews/customize/customizable_item_v2'
  });
};

export interface ICustomizeItemParams {
  item_id: string;
}

export interface ICustomItem {
  mainImage: string;
  customHeight: number;
  customWidth: number;
  yResolution: number;
  xResolution: number;
  templateId: number;
  region: string;
  type?: string;
  typeName?: string;
}

export interface ICustomizeItemResult {
  mainImage: string;
  customHeight: number;
  customWidth: number;
  yResolution: number;
  xResolution: number;
  templateId: number;
  recommendImageList: string[];
  customList: ICustomItem[];
}

export const getCustomizeItemV1 = (
  payload: ICustomizeItemParams
): Promise<ICustomizeItemResult> => {
  return hhoRpc({
    method: 'get',
    headers: {},
    payload,
    url: 'order_reviews/customize/customizable_item'
  });
};

export const getUploadToken = (payload?: any) => {
  return hhoRpc({
    method: 'get',
    headers: {},
    payload,
    url: '/order_reviews/customize/sts_token'
  });
};
export interface IFetchDataParams {
  itemId: string | (string | null)[] | null;
}

// 获取商品详情
export const noLoginProduct = (p: IFetchDataParams) => {
  const queryPayload: any = {
    itemId: p.itemId
  };
  // aob && (queryPayload.abtest = aob);
  return hhoRpc({
    method: 'get',
    headers: {},
    payload: queryPayload,
    url: getFullUrl(`/i/item/outer/${p.itemId}`)
  });
};

export interface IMaterialListItem {
  text?: string;
  type?: string;
  mainImage: string;
  customizedImage: string;
}

export interface IUploadCustomizeInfo {
  text?: string;
  type?: string;
  itemId: string;
  skuId?: string;
  materialList: IMaterialListItem[];
}

export const uploadCustomizeInfo = (payload: IUploadCustomizeInfo): Promise<string> => {
  return hhoRpc({
    method: 'post',
    headers: {},
    payload,
    url: '/order_reviews/customize/upload_customization'
  });
};

export const getTshirtItems = (payload?: any) => {
  return hhoRpc({
    method: 'get',
    headers: {},
    payload,
    url: 'order_reviews/customize/active_items'
  });
};

export interface IUploadOrder {
  name: string;
  email: string;
  number: number;
  phone?: string;
  demand: string;
}
export const uploadOrder = (payload: IUploadOrder) => {
  return hhoRpc({
    method: 'post',
    headers: {},
    payload,
    url: 'order_reviews/customize/shop/upload_work_order'
  });
};

export const getList = (payload?: {}) => {
  return hhoRpc({
    method: 'get',
    headers: {},
    payload,
    url: 'order_reviews/customize/shop/customize_item/list'
  });
};

interface IRecommendGoodsResult {
  infoPackage: {
    dataList: {
      itemId: string;
      prePrice: number;
      mainImage: string;
      title: string;
    }[];
    hasMore: boolean;
    nextCursor: number | null;
  };
}

export const getRecommend = (payload: any) => {
  return hhoRpc({
    method: 'get',
    headers: {},
    payload,
    url: 'order_reviews/customize/shop/recommend_items'
  });
};

interface IMaterialsParams {
  series: string;
  page: number;
  size: number;
}

export interface IMaterialListItem {
  imageURL: string;
  materialId: string;
}
interface IMaterialsResult {
  materialPageDTO: {
    dataList: IMaterialListItem[];
    hasMore: boolean;
    nextCursor: number;
  };
}
export const getMaterials = (payload: IMaterialsParams): Promise<IMaterialsResult> => {
  return hhoRpc({
    method: 'get',
    headers: {},
    payload,
    url: 'order_reviews/customize/shop/customize_item/material/list'
  });
};

export const itemCustomArea = function (): Promise<any> {
  return hhoRpc({
    headers: {},
    method: 'get',
    payload: {},
    url: 'order_reviews/customize/item_custom_area'
  });
};
